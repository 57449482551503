@import 'assets/scss//constants.scss';

.filter__closed {
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    padding: 4px 12px;
    cursor: pointer;
    .icon {
        margin-left: 10px;
    }
}

.dropdown__container {
    cursor: pointer;
    background-color: white;
    padding: 8px;
    min-width: 230px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    .checkbox {
        padding-top: 2px;
        padding-bottom: 2px;
    }
    .input {
        margin-bottom: 8px;
    }

    .buttons {
        display: flex;
        padding: 8px;
        padding-left: 15px;
        margin: -8px;
        margin-top: 10px;
        justify-content: space-between;
        border-top: 1px solid rgba(0, 0, 0, 0.06);
        .clear {
            color: rgba(0, 0, 0, 0.25);
            cursor: pointer;
        }
    }
}
