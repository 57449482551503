@import './constants.scss';
//COMMONS
@import 'commons/reset';
:root {
    font-family: $font;
}

.ant-table-container {
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-bottom: 0;
}

.ant-table-cell {
    padding: 14px 16px !important;
}
