.editPassword__container {
     /* Auto layout */
     display: flex;
     flex-direction: column;
     justify-content: center;
     // align-items: center;
     padding: 0px;
     gap: 15px;
     position: absolute;
     width: 380px;
     left: calc(50% - 380px / 2);
}
.editPassword__title,
.editPassword__subtitle {
    text-align: left;
}
.editPassword__form {
    width: inherit;
}

.editPassword__input {
    width: inherit;
    margin-bottom: 16px;
}

.editPassword__btn {
    width: inherit;
}

.editPassword__forgottenPassowrd {
    margin-bottom: 32px;
    width: 100%;
    justify-content: flex-end;
}
