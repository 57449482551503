.actionsBar__container {
    padding: 12px;
    background: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-bottom: 1px;
    height: 57px;
}

.actionsBar__search {
    width: auto !important;
}

.actionsBar__buttons {
    margin-left: 12px;
    height: 32px;
}
