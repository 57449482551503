.newaccountform__input {
    margin-bottom: 10px;
}

.wrapper__fullWidth {
    width: 100% !important;
}

.newwork__row {
    justify-content: space-between;
    gap: 0 !important;
    display: flex !important;
    margin-bottom: 10px;
}
