.profile__container {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    padding: 0px;
    gap: 15px;
    position: absolute;
    width: 380px;
    left: calc(50% - 380px / 2);
}
.profile__title,
.profile__subtitle {
    text-align: left;
}
.profile__form {
    width: inherit;
}

.profile__input {
    margin-bottom: 16px;
}

.profile__select {
    width: 100%;
}

.profile__btn {
    width: inherit;
}

.profile__changePassword {
    margin-bottom: 32px;
    width: 100%;
    justify-content: flex-end;
}
