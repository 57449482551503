@import '../../assets/scss/constants.scss';

.wrapper__label {
    line-height: 22px;
    margin-bottom: 8px;
}

.wrapper__tooltip {
    margin-left: 5px;
}

.wrapper__tooltipIcon {
    color: $gray;
}

.wrapper__optional {
    color: $gray;
    margin-left: 5px;
}