@import 'assets/scss//constants.scss';

.filters__container {
    margin-bottom: 12px;
    margin-left: 24px;
    gap: 16px !important;
}

.filters__remove {
    margin-left: 10px;
    color: $primary !important;
    cursor: pointer;
}
