@import 'assets/scss//constants.scss';

.filter__closed {
    cursor: pointer;
    color: $primary;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 36px;

    .icon {
        margin-left: 10px;
    }
    .closeIcon {
        margin-left: 10px;
        background: rgba(255, 255, 255, 1e-5);
    }
    .filtersCount {
        color: inherit;
        margin-right: 5px;
    }
    .placeholder {
        color: inherit;
    }
}

.filled {
    background-color: $primary1;
    padding: 5px 12px;
}

.dropdown__container {
    cursor: pointer;
    background-color: white;
    padding: 8px;
    min-width: 230px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    .checkbox {
        padding-top: 2px;
        padding-bottom: 2px;
    }
    .input {
        margin-bottom: 8px;
    }

    .buttons {
        display: flex;
        padding: 8px;
        padding-left: 15px;
        margin: -8px;
        margin-top: 10px;
        justify-content: space-between;
        border-top: 1px solid rgba(0, 0, 0, 0.06);
        .clear {
            color: rgba(0, 0, 0, 0.25);
            cursor: pointer;
        }
    }
}
