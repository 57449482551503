.forgottenPassword__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 16px;
    position: absolute;
    width: 380px;
    left: calc(50% - 380px / 2);
    top: calc(50% - 364.8px / 2 + 0.4px);
}

.forgottenPassword__title {
    margin-top: 16px;
}

.forgottenPassword__form {
    width: inherit;
}

.forgottenPassword__input {
    width: inherit;
    margin-bottom: 32px;
}

.forgottenPassword__btn {
    width: inherit;
    margin-bottom: 16px
}

.forgottenPassword__forgottenPassowrd {
    margin-bottom: 32px;
    width: 100%;
    justify-content: flex-end;
}
