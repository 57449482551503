@import 'assets/scss/constants.scss';

.userAvatar__container {
    gap: 0 !important;
}
.account {
    // margin-left: 8px !important;
    margin-right: 8px !important;
    color: $orange-text-color !important;
    background-color: $orange !important;
    width: 28px !important;
    height: 28px !important;
    line-height: 28px !important;
}

.secondary {
    color: $primary !important;
    background-color: $primary-text-color !important;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 1000px) {
    .label {
        display: none;
    }
  }