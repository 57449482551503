// colori
@import url('https://fonts.googleapis.com/css2?family=Roboto');

// TYPOGRAPHY
$font: 'Roboto';

$primary1: #e6f7ff;


// primary 6
$primary: #1890ff;
$primary-text-color: #bae7ff;

// Volcano /2
$orange: #ffd8bf;
$orange-text-color: #fa541c;

$gray: gray;

$primary9: rgba(0, 58, 140, 1);

$black:  rgba(0, 0, 0, 0.451);


/* DayBreak Blue/2 */

