@import '../../assets/scss/constants.scss';

.propertycard__container {
    width: 350px;
}

.propertycard__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    h5 {
        margin-bottom: 0 !important;
    }
}

.propertycard__searchInput {
    margin-top: 10px;
}

.propertycard__addButton {
    margin-top: 10px;
    width: 100%;
    margin-bottom: 20px;
}

.propertycard__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 8px;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    margin-bottom: 10px;
    font-weight: 500;
}

.propertycard__deleteIcon {
    color: $black;
}
