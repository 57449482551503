* {
    box-sizing: border-box;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

*::-webkit-scrollbar {
    display: none !important;
    /* For Chrome, Safari, and Opera */
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(8, 123, 187, 0.1);
    // overflow: hidden;
}

body {
    margin: 0;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility;
    // text-rendering: geometricPrecision;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    box-sizing: border-box;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    // overflow-y: auto;
}

body::-webkit-scrollbar {
    display: none !important;
    /* For Chrome, Safari, and Opera */
}

h1 {
    margin: 0;
    padding: 0;
    font-size: inherit;
}

h2 {
    margin: 0;
    padding: 0;
    font-size: inherit;
}

h3 {
    margin: 0;
    padding: 0;
    font-size: inherit;
}

h4 {
    margin: 0;
    padding: 0;
    font-size: inherit;
}

h5 {
    margin: 0;
    padding: 0;
    font-size: inherit;
}

h6 {
    margin: 0;
    padding: 0;
    font-size: inherit;
}

p {
    margin: 0;
    padding: 0;
}

blockquote {
    margin: 0;
    padding: 0;
}

figure {
    margin: 0;
    padding: 0;
}

ol {
    margin: 0;
    padding: 0;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

strong {
    font-weight: 500;
}

a {
    color: inherit;
    text-decoration: none;
}

button {
    color: inherit;
    overflow: visible;
    border: 0;
    font: inherit;
    -webkit-font-smoothing: inherit;
    letter-spacing: inherit;
    background: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    &::-moz-focus-inner {
        padding: 0;
        border: 0;
    }
    // &:focus {
    //     // outline: 0;
    // }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

img {
    border: none;
}

textarea,
input:matches([type='email'], [type='number'], [type='password'], [type='search'], [type='tel'], [type='text'], [type='url']) {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

//HIDE ARROWS ON INPUT NUMBER

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */

input[type='number'] {
    -moz-appearance: textfield;
}

//CHECKBOX/RADIO
input[type='checkbox'],
input[type='radio'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 0px;
    height: 0px;
    opacity: 0;
    border: none;
    padding: 0px;
    margin: 0px;
}
