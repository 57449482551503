@import '../../assets/scss/constants.scss';

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6.4px 15px;
    gap: 10px;
    height: 40px;
    border: 1px solid $primary;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
}

.button_link {
    margin-left: 10px !important;
    &:hover {
    color: white !important;
}
}

