.createPassword__container {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 32px;
    position: absolute;
    width: 380px;
    //height: 410px;
    left: calc(50% - 380px / 2);
    top: calc(50% - 410px / 2);
}
.createPassword__title,
.createPassword__subtitle {
    text-align: center;
}
.createPassword__form {
    width: inherit;
}

.createPassword__input {
    width: inherit;
    margin-bottom: 16px;
}

.createPassword__btn {
    width: inherit;
}

.createPassword__forgottenPassowrd {
    margin-bottom: 32px;
    width: 100%;
    justify-content: flex-end;
}
