@import '../../assets/scss/constants.scss';
.navbar__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 100px;
    gap: 10px;
    min-width: 1000px;
    position: sticky; 
    width: 100%;
    left: 0px;
    top: 0px;
    z-index: 999;
    background: #ffffff !important;
    box-shadow: 0px 2px 8px #f0f1f2;
    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .logo {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .img {
        height: 25px;
        cursor: pointer;
    }
    .lng {
        gap: 8px;
        margin: 8px 5px 0px 20px;
        padding-bottom: 5px;
        border-bottom: 1px solid $primary;
        color: $primary;
    }
    .select {
        color: '#1890FF';
        padding-right: 0;
    }
    .support {
        cursor: pointer;
        color: black;
    }
    .supportIcon {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .navbar__container {
        padding: 0px 30px;
    }
}

@media screen and (max-width: 1000px) {
    .navbar__container {
        padding: 0px 50px;
        .support {
            display: none;
        }
        .supportIcon {
            cursor: pointer;
            display: inline;
        }
        .questionIcon {
            margin-right: 10px;
        }
    }
}
