.mainLayout {
    background: white !important;
   
}

.mainContent {
    padding: 90px 70px 50px 70px;
    min-width: 1000px;
    overflow: scroll;
}

@media screen and (max-width: 1200px) {
    .mainContent {
        padding: 90px 20px 50px 20px;
    }
}
