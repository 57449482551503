.loginform__container {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 32px;
    position: absolute;
    width: 380px;
    //height: 410px;
    left: calc(50% - 380px / 2);
    top: calc(50% - 410px / 2);
}

.loginform__form {
    width: inherit;
}

.loginform__input {
    width: inherit;
    margin-bottom: 16px;
}

.loginform__btn {
    width: inherit;
}

.loginform__forgottenPassowrd {
    margin-bottom: 32px;
    width: 100%;
    justify-content: flex-end;
}
